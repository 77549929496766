<!-- 涉农补贴 -->
<template>
  <div class="snbt">
    <!-- 农机购置补贴前端页面 -->
    <!-- 弹窗 -->
    <el-dialog
    :visible.sync="dialogVisible"
    width="30%"
   >
    <div class="dialogContent">
      <div class="dialogTitle"> <span>{{userNotice.newUserTitle}}</span> </div>
      <p> <span class="tit">补贴对象：</span> <span class="cont">{{userNotice.newSubsidyType}}</span> </p>
      <p> <span class="tit">申请时间：</span> <span class="cont">{{userNotice.applyStartTime}}——{{userNotice.applyEndTime}}</span> </p>
      <div>
        <span class="tit">补贴要求：</span>
        <div>
          <p class="cont">{{userNotice.subsidyRequire}}</p>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
    </span>
    </el-dialog>

    <img src="../../assets/banner-1.png" alt="">
    <div class="subsidyBox" v-if="subsidyTypeList.length">
      <div class="contentItem" v-for="(item,index) in subsidyTypeList" :key="index">
        <div class="innerBox">
       <img
        src="../../assets/subsidy.png"
       />
          <span class="tit">{{item.nowYear}}</span>
          <span class="tit">{{item.description}}</span>

          <div class="content">
            <span class="tit2">补贴对象：</span>
            <div class="cont">
              <!-- <span v-for="(item,index) in item.serviceType2" :key="index">
                  {{ item }}、
              </span> -->
              <span>{{item.serviceType2}}</span>
            </div>
          </div>

          <div class="content">
            <span class="tit2">申请时间：</span>
            <div class="cont">
                <span class="time">{{item.applyStartTime}}——{{item.applyEndTime}}</span>
            </div>

          </div>
          <div class="Btn">
            <el-button type="text" @click="TipsBtn(item.twActivitySubsidyTypeVo)">申报须知</el-button>
            <el-button type="primary" @click="applySubsidy(item)" >立即申报</el-button>
          </div>
        </div>

      </div>
    </div>
    <div class="subsidyBox2"  v-else>
      <el-empty description="暂无数据"></el-empty>
    </div>

  </div>
</template>
<script>

import { mapGetters } from "vuex";
import {selectFrontSubsidyTypeList} from '@/api/subsidy'
import {subsidytype} from '@/api/snbt'
export default{
 data(){
  return {
    dialogVisible:false,
    subsidyTypeList:[],
    subsidyTypes:[],
    userNotice:{},
    types: [
        {
          label: "集体经济组织",
          value: "3",
        },
        {
          label: "农业企业",
          value: "41",
        },
        {
          label: "家庭农场",
          value: "42",
        },
        {
          label: "合作社",
          value: "43",
        },
        {
          label: "农户",
          value: "2",
        },
    ],  // 主体类型
  };

 },
 watch: {
    topSelectAreacode: function () {
      this.getSubsidyTypeList();
    },
  },
 computed: {
    ...mapGetters(["identityObj",'topSelectAreacode']),
  },
  mounted(){
     this.getSubsidyTypeList()
     this.getSubsidyType()
  },

 methods:{
    // 获取补贴类型列表
    async getSubsidyTypeList(){
      let params={
        areaCode:this.topSelectAreacode?this.topSelectAreacode:null
      }
      let res = await selectFrontSubsidyTypeList(params)
      if(res.code==0){
        let newData=res.data.map((item,index)=>{
          let newServiceType = item.serviceType.split(',');
          let newType2=this.subsidyTypes.find(its=> item.subsidyType==its.value).description
          let newType = newServiceType.filter(its => this.types.find(type => type.value === its)).map(its => this.types.find(type => type.value === its).label); // 使用 find 来确认类型是否存在
            return {
                ...item,
                serviceType2: newType.join('、'), // 用逗号和空格连接.join(', ')
                description:newType2
            }
        })
        this.subsidyTypeList =newData
      }
    },
    // 获取补贴类型列表
    async getSubsidyType(){
      let res = await subsidytype()
      if(res.code==0){
          this.subsidyTypes= res.data
      }
    },
  // 用户须知
   TipsBtn(obj){

    let newTypes= obj.serviceType.split(',')
    let newUserTitle=this.subsidyTypes.find(its=> obj.subsidyType==its.value).description
    let newSubsidyType=newTypes.filter(its=>this.types.find(type => type.value === its)).map(its => this.types.find(type => type.value === its).label).join('、')
    obj.newUserTitle=newUserTitle
    obj.newSubsidyType=newSubsidyType
    this.userNotice=obj
    // 种粮大户补贴申报须知
    this. dialogVisible=true
  },
  // 申报
  applySubsidy(obj){
    console.log(obj,'查看obj是什么')
    // let objType={
    //   subsidyType:obj.subsidyType,
    //   subsidyId: obj.subsidyId
    // }
    if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      //项目的补贴的类型
      let subsidyType=obj.subsidyType
      //项目服务的人群
      let  serviceTypeList= obj.serviceType.split(',');
        //登录人的身份类别
      let identityTypeCode=this.identityObj.identityTypeCode
      console.log(serviceTypeList,'查看serviceTypeList是什么')
      if(subsidyType=="1"){
        let isTrue=serviceTypeList.includes(identityTypeCode)
        if(!isTrue){
          this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
          return
        }
        this.$router.push({path:'/btsqtx/grainSubsidy',query:{
          // objType:objType
          subsidyId:obj.subsidyId,
          subsidyType: obj.subsidyType,
        }})
      }else if(subsidyType=="4"){
        let isTrue=serviceTypeList.includes(identityTypeCode)
        if(!isTrue){
          this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
          return
        }
        this.$router.push({path:'/btsqtx/socialAllowance',query:{
          // objType:objType
          subsidyId:obj.subsidyId,
          subsidyType: obj.subsidyType,
        }})
      }else if(subsidyType=="3"){
        let isTrue=serviceTypeList.includes(identityTypeCode)
        if(!isTrue){
          this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
          return
        }
        this.$router.push({path:'/btsqtx/farmMachinery',query:{
          // objType:objType
          subsidyId:obj.subsidyId,
          subsidyType: obj.subsidyType,
        }})
      }


  },

 }
}
</script>

<style lang="less" scoped>
.snbt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: auto;
  img{
      width:1500px;
      height:200px;
  }
  .subsidyBox{
    width: 70%;
    min-height: 500px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .contentItem{
      width:30%;
      height: 400px;
      display: flex;
      margin-top: 15px;
      margin-bottom:15px;
      flex-direction: column;
      align-items: center;
      .innerBox{
        width:50%;
        height: 400px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        border: n;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        .tit{
          font-size: 24px;
          font-weight: 800;
        }
        img{
          width:90px;
          height:100px;
        }
        .content{
          width:100%;
          padding-top:10px;
          padding-left: 10px;
          .tit2{
            font-size: 18px;
            font-weight: 800;
            margin-top: 50px;
          }
          .cont{
            min-height:40px;
            margin-top:10px;
          }
          span{
            font-size: 17px;
          }
          .time{
            font-size: 17px;
          }
        }
        .Btn{
          width:100%;
          display: flex;
          justify-content: space-around;
          position: absolute;
          bottom: 10px;
        }

      }
    }
  }


}
/deep/.el-dialog__header{
  background: #ffff !important;
  padding:0 !important;
}
/deep/.dialogContent{

  .dialogTitle{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
  }
  .tit{
    font-weight: 700;
    font-size: 17px;
  }
  .cont{
    font-size: 17px;

  }


}


</style>
